import PropTypes from 'prop-types'
import {If, For} from 'babel-plugin-jsx-control-statements'
import {map, isEmpty} from 'lodash-es'
import {useI18next} from 'gatsby-plugin-react-i18next'
import {Accordion, AccordionContainer, ButtonCard, Section, Text, ContentContainer, Button} from 'uikit'
import BaseLink from '../BaseLink'


const AboutChallenge = ({title, Challenges, buttonLink, buttonText}) => {
  const {language: currentLang} = useI18next()
  const mappedData = map(Challenges, (data, index) => ({index, ...data}))
  return (
    <ContentContainer>
      <Section>
        <Text component="h2" textStyle="h2" align="center">{title}</Text>
        <AccordionContainer>
          <For each="item" of={mappedData} index="idx">
            <If condition={!isEmpty(item?.title)}>
              <Accordion
                  key={item?.index}
                  order={String(item.index + 1)}
                  title={<Text component="h3" textStyle="h4">{item?.title}</Text>}
                  description={item?.description}
              >
                {item?.text}
              </Accordion>
            </If>
          </For>
          <ButtonCard
              small
              button={
                <Button as={BaseLink} to={buttonLink} language={currentLang}>
                  {buttonText}
                </Button>
              }
          />
        </AccordionContainer>
      </Section>
    </ContentContainer>
  )
}

AboutChallenge.propTypes = {
  title: PropTypes.string,
  Challenges: PropTypes.array,
  buttonLink: PropTypes.string,
  buttonText: PropTypes.string,
}

export default AboutChallenge
