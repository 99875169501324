import PropTypes from 'prop-types'
import {isEmpty} from 'lodash-es'
import {If} from 'babel-plugin-jsx-control-statements'
import {useI18next} from 'gatsby-plugin-react-i18next'
import {HighlightedImageCard, Section, Button, Text, ContentContainer} from 'uikit'
import Image from '../Image'
import BaseLink from '../BaseLink'


const AboutUs = ({title, description, buttonText, buttonLink, image}) => {
  const {language: currentLang} = useI18next()
  return (
    <ContentContainer>
      <Section>
        <HighlightedImageCard
            title={<Text component="h2" textStyle="h3">{title}</Text>}
            // eslint-disable-next-line react/no-danger
            description={<div dangerouslySetInnerHTML={{__html: description}} />}
            image={<Image image={image?.localFile} alt={image?.alternativeText} />}
        >
          <If condition={!isEmpty(buttonText) && !isEmpty(buttonLink)}>
            <Button component={BaseLink} to={buttonLink} language={currentLang}>
              {buttonText}
            </Button>
          </If>
        </HighlightedImageCard>
      </Section>
    </ContentContainer>
  )
}

AboutUs.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  image: PropTypes.object,
}

export default AboutUs
