import {useState} from 'react'
import PropTypes from 'prop-types'
import {isEmpty} from 'lodash-es'
import {If, For} from 'babel-plugin-jsx-control-statements'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import {HighlightedImageCard, Section, Button, ContentContainer, Slider, SSRSuspense} from 'uikit'
import Image from '../Image'
import BaseLink from '../BaseLink'


const Event = ({jumbotron}) => {
  const [control, setContrtol] = useState(null)
  const {language: currentLang} = useI18next()
  const {t} = useTranslation()
  return (
    <ContentContainer>
      <Section>
        <SSRSuspense fallback={
          <HighlightedImageCard
              title={<h3>{jumbotron[0]?.title}</h3>}
              // eslint-disable-next-line react/no-danger
              description={<div dangerouslySetInnerHTML={{__html: jumbotron[0]?.description}} />}
              image={
                <Image
                    image={jumbotron[0]?.image?.localFile}
                    alt={jumbotron[0]?.image?.alternativeText}
                />}
          >
            <If condition={!isEmpty(jumbotron[0]?.buttonText) && !isEmpty(jumbotron[0]?.buttonLink)}>
              <Button component={BaseLink} to={jumbotron[0]?.buttonLink} language={currentLang}>
                {jumbotron[0]?.buttonText}
              </Button>
            </If>
          </HighlightedImageCard>
        }
        >
          <Slider
              autoplay
              centeredSlides
              pagination
              preventClicksPropagation={false}
              effect="fade"
              spaceBetween={0}
              prevArrowTitle={t('event.slider.left')}
              nextArrowTitle={t('event.slider.right')}
              control={control}
              onSwiper={setContrtol}
          >
            <For each="slide" of={jumbotron} index="idx">
              <HighlightedImageCard
                  title={<h3>{slide?.title}</h3>}
                  // eslint-disable-next-line react/no-danger
                  description={<div dangerouslySetInnerHTML={{__html: slide?.description}} />}
                  image={
                    <Image
                        image={slide?.image?.localFile}
                        alt={slide?.image?.alternativeText}
                    />}
                  key={`${idx}${slide?.title}`}
              >
                <If condition={!isEmpty(slide?.buttonText) && !isEmpty(slide?.buttonLink)}>
                  <Button component={BaseLink} to={slide?.buttonLink} language={currentLang}>
                    {slide?.buttonText}
                  </Button>
                </If>
              </HighlightedImageCard>
            </For>
          </Slider>
        </SSRSuspense>
      </Section>
    </ContentContainer>
  )
}

Event.propTypes = {
  jumbotron: PropTypes.arrayOf(PropTypes.shape({
    buttonLink: PropTypes.string,
    buttonText: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.object,
    title: PropTypes.string,
  })),
}

export default Event
