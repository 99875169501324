import PropTypes from 'prop-types'
import {Iframe as VisualIframe} from 'uikit'


const Iframe = ({iframeLink, ...props}) => {
  return (
    <VisualIframe iframeLink={iframeLink} {...props} />
  )
}

Iframe.propTypes = {
  iframeLink: PropTypes.string.isRequired,
}

export default Iframe
