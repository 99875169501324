import PropTypes from 'prop-types'
import {If, For} from 'babel-plugin-jsx-control-statements'
import {isEmpty} from 'lodash-es'
import {Accordion, AccordionContainer, Section, Text, ContentContainer} from 'uikit'


const FAQ = ({title, Questions}) => {
  return (
    <ContentContainer>
      <Section>
        <Text component="h2" textStyle="h2" align="center">{title}</Text>
        <AccordionContainer>
          <If condition={!isEmpty(Questions)}>
            <For each="item" of={Questions} index="idx">
              <Accordion
                  key={idx}
                  title={<Text component="h3" textStyle="h4">{item?.title}</Text>}
              >
                {item?.description}
              </Accordion>
            </For>
          </If>
        </AccordionContainer>
      </Section>
    </ContentContainer>
  )
}

FAQ.propTypes = {
  title: PropTypes.string,
  Questions: PropTypes.array,
}


export default FAQ
