import PropTypes from 'prop-types'
import {isEmpty} from 'lodash-es'
import {If, For} from 'babel-plugin-jsx-control-statements'
import {
  Hero as VisualHero, InfoCard, QuestionIcon, MapIcon, CarIcon, CalendarIcon, Bike2Icon, Bike3Icon, BikeIcon,
  ClimbingIcon, HikerClimbingIcon, HikerIcon, SkiIcon, TouristIcon, AIIcon, GrowthIcon, InfinityProductIcon,
  InfinityIcon, MindShapeIcon, ModelingIcon, PerformanceIcon, RenderingIcon, ScalingIcon, ScaleUpIcon, ScaleIcon,
} from 'uikit'
import Image from '../Image'


const icons = {
  bike2: <Bike2Icon noFill size="xxl" />,
  bike3: <Bike3Icon noFill size="xxl" />,
  bike: <BikeIcon noFill size="xxl" />,
  calendar: <CalendarIcon noFill size="xxl" />,
  car: <CarIcon noFill size="xxl" />,
  climbing: <ClimbingIcon noFill size="xxl" />,
  hiker: <HikerIcon noFill size="xxl" />,
  hikerClimbing: <HikerClimbingIcon noFill size="xxl" />,
  map: <MapIcon noFill size="xxl" />,
  question: <QuestionIcon noFill size="xxl" />,
  ski: <SkiIcon noFill size="xxl" />,
  tourist: <TouristIcon noFill size="xxl" />,
  ai: <AIIcon noFill size="xxl" />,
  growth: <GrowthIcon noFill size="xxl" />,
  infinityProduct: <InfinityProductIcon noFill size="xxl" />,
  infinity: <InfinityIcon noFill size="xxl" />,
  mindShape: <MindShapeIcon noFill size="xxl" />,
  modeling: <ModelingIcon noFill size="xxl" />,
  performance: <PerformanceIcon noFill size="xxl" />,
  rendering: <RenderingIcon noFill size="xxl" />,
  scaling: <ScalingIcon noFill size="xxl" />,
  scaleUp: <ScaleUpIcon noFill size="xxl" />,
  scale: <ScaleIcon noFill size="xxl" />,
}

const Hero = ({bgImage, brand, description, infoCard}) => {
  return (
    <VisualHero
        brand={<Image image={brand.brandLogo.localFile} alt={brand.brandLogo.alternativeText} loading="eager" />}
        bgImage={bgImage?.localFile?.publicURL}
        // eslint-disable-next-line react/no-danger
        description={<div dangerouslySetInnerHTML={{__html: description}} />}
    >
      <If condition={!isEmpty(infoCard)}>
        <For each="card" of={infoCard} index="idx">
          <InfoCard key={idx} icon={icons[card?.icon]} title={card?.title} description={card?.description} />
        </For>
      </If>
    </VisualHero>
  )
}

Hero.propTypes = {
  bgImage: PropTypes.object,
  brand: PropTypes.object,
  description: PropTypes.string,
  infoCard: PropTypes.array,
}

export default Hero
