import {cloneElement, Fragment} from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import {If, For} from 'babel-plugin-jsx-control-statements'
import {Main} from 'uikit'
import {isEmpty} from 'lodash-es'
import AppShell from '../components/AppShell'
import Hero from '../components/sections/Hero'
import Event from '../components/sections/Event'
import AboutChallenge from '../components/sections/AboutChallenge'
import Inlcluded from '../components/sections/Included'
import AboutUs from '../components/sections/AboutUs'
import Faq from '../components/sections/FAQ'
import Iframe from '../components/sections/Iframe'
import Packages from '../components/sections/Packages'


const components = {
  'sections.hero': <Hero />,
  'sections.jumbotron-section': <Event />,
  'sections.about-challenge': <AboutChallenge />,
  'sections.faq': <Faq />,
  'sections.about-us': <AboutUs />,
  'sections.included': <Inlcluded />,
  'sections.iframe': <Iframe />,
  'sections.packages': <Packages />,
}

const Page = ({data}) => {
  const pageData = data?.strapiPage

  return (
    <AppShell title={pageData?.title} brand={pageData?.brand} seo={pageData?.Seo}>
      <h1 className="sr-only">{pageData?.title}</h1>
      <If condition={!isEmpty(pageData?.Components)}>
        <Main>
          <For each="component" of={pageData?.Components} index="idx">
            <Fragment key={idx}>
              <If condition={!isEmpty(component)}>
                {
                  cloneElement(components[component.strapi_component], {
                    ...component, brand: pageData?.brand, to: component?.paymentPage?.slug,
                  })
                }
              </If>
            </Fragment>
          </For>
        </Main>
      </If>
    </AppShell>
  )
}

export const query = graphql`
  query ($language: String!, $id: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    strapiPage(id: {eq: $id}) {
      title
      Components {
        ... on STRAPI__COMPONENT_SECTIONS_HERO {
          id
          bgImage {
            alternativeText
            localFile {
              publicURL
            }
          }
          description
          infoCard {
            icon
            description
            title
          }
          strapi_component
        }
        ... on STRAPI__COMPONENT_SECTIONS_IFRAME {
          id
          iframeLink
          strapi_component
        }
        ... on STRAPI__COMPONENT_SECTIONS_JUMBOTRON_SECTION {
          strapi_component
          jumbotron {
            buttonLink
            buttonText
            description
            title
            image {
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    transformOptions: {fit: COVER}
                    placeholder: BLURRED
                  )
                }
              }
              alternativeText
            }
          }
        }
        ... on STRAPI__COMPONENT_SECTIONS_ABOUT_CHALLENGE {
          Challenges {
            title
            description
            text
          }
          buttonLink
          buttonText
          strapi_component
          title
        }
        ... on STRAPI__COMPONENT_SECTIONS_FAQ {
          title
          strapi_component
          Questions {
            title
            description
          }
        }
        ... on STRAPI__COMPONENT_SECTIONS_ABOUT_US {
          title
          description
          strapi_component
          image {
            alternativeText
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  transformOptions: {fit: COVER}
                  placeholder: BLURRED
                )
              }
            }
          }
          buttonLink
          buttonText
        }
        ... on STRAPI__COMPONENT_SECTIONS_INCLUDED {
          strapi_component
          title
          Cards {
            image {
              alternativeText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    transformOptions: {fit: COVER}
                    placeholder: BLURRED
                  )
                }
              }
            }
            asterisk
            description
            title
          }
          NotIncluded {
            buttonLink
            buttonText
            description
            title
          }
        }
        ... on STRAPI__COMPONENT_SECTIONS_PACKAGES {
          strapi_component
          paymentPage {
            slug
            packages {
              strapi_id
              order
              highlight
              features {
                data {
                  childMarkdownRemark {
                    html
                  }
                }
              }
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      transformOptions: {fit: COVER}
                      placeholder: BLURRED
                    )
                  }
                }
              }
              title
              price
              currency
            }
          }
        }
      }
      brand {
        facebookDomainVerification
        brandLogo {
          alternativeText
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                transformOptions: {fit: COVER}
                placeholder: BLURRED
              )
            }
          }
        }
        email
        gdprLinkText
        gdprLink
        socials {
          icon
          link
        }
        footerLogo {
          alternativeText
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                transformOptions: {fit: COVER}
                placeholder: BLURRED
              )
            }
          }
        }
        FooterLinks {
          link
          text
        }
        footerText
      }
      Seo {
        metaTitle
        metaDescription
        image {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`

Page.propTypes = {
  data: PropTypes.object,
}

export default Page
