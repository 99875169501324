import PropTypes from 'prop-types'
import {If, For} from 'babel-plugin-jsx-control-statements'
import {useI18next} from 'gatsby-plugin-react-i18next'
import {Card, CardsContainer, Section, Text, ButtonCard, ContentContainer, Button} from 'uikit'
import {isEmpty} from 'lodash-es'
import Image from '../Image'
import BaseLink from '../BaseLink'


const Included = ({title, Cards, NotIncluded}) => {
  const {language: currentLang} = useI18next()
  return (
    <ContentContainer>
      <Section>
        <Text component="h2" textStyle="h2" align="center">{title}</Text>
        <CardsContainer>
          <If condition={!isEmpty(Cards)}>
            <For each="item" of={Cards} index="idx">
              <Card
                  key={idx}
                  title={<h3>{item?.title}</h3>}
                  // eslint-disable-next-line react/no-danger
                  description={<div dangerouslySetInnerHTML={{__html: item?.description}} />}
                  image={<Image image={item?.image?.localFile} alt={item?.image?.alternativeText} />}
              >
                {item?.asterisk}
              </Card>
            </For>
          </If>
          <ButtonCard
              button={
                <Button component={BaseLink} to={NotIncluded?.buttonLink} language={currentLang}>
                  {NotIncluded?.buttonText}
                </Button>
              }
          >
            <h3>{NotIncluded?.title}</h3>
            <p>{NotIncluded?.description}</p>
          </ButtonCard>
        </CardsContainer>
      </Section>
    </ContentContainer>
  )
}

Included.propTypes = {
  title: PropTypes.string,
  Cards: PropTypes.array,
  NotIncluded: PropTypes.object,
}

export default Included
